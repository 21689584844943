import { Component, DestroyRef, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { HeaderComponent } from '@components/common/header/header.component';
import { FooterComponent } from '@components/common/footer/footer.component';
import { MobileFooterComponent } from '@components/common/mobile-footer/mobile-footer.component';
import { NotNetworkComponent } from '@components/common/not-network/not-network.component';

import { B24CrmButtonComponent } from '@components/pages/home/b24-crm-button/b24-crm-button.component';

import { UIService } from '@services/ui.service';

@Component({
  selector: 'app-main-layout',
  imports: [
    HeaderComponent,
    FooterComponent,
    MobileFooterComponent,
    NotNetworkComponent,
    RouterOutlet,
    B24CrmButtonComponent,
  ],
  templateUrl: './main-layout.component.html',
  styleUrl: './main-layout.component.scss',
})
export class MainLayoutComponent {
  destroyRef = inject(DestroyRef);
  isMobile = this.uiService.isMobile;
  isNotNetwork = this.uiService.isNotNetwork;
  removeMainScroll = this.uiService.removeMainScroll;

  constructor(private uiService: UIService) {}
}
