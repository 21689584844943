<div class="external-layout">
  <app-logo-link
    [fillLogo]="isAdaptiveLogo() ? '#6F9305' : '#ffffff'"
    [fillText]="isAdaptiveLogo() ? '#050609' : '#ffffff'"
    class="logo"
  />

  <div class="side-block">
    <img src="/assets/auth-img.png" alt="Закрома" class="img" />
  </div>

  <div class="content">
    @if (isNotNetwork()) {
      <app-not-network [isExternalLayout]="true" />
    } @else {
      <ng-content />
    }
  </div>
</div>
