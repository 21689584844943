import { Component, input, output, signal } from '@angular/core';

import { DrawerComponent } from '@components/common/drawer/drawer.component';
import { CategoryLinkComponent } from '../category-link/category-link.component';
import { ReferencesService } from '@services/references.service';

@Component({
  selector: 'app-categories-drawer',
  imports: [DrawerComponent, CategoryLinkComponent],
  templateUrl: './categories-drawer.component.html',
  styleUrl: './categories-drawer.component.scss',
})
export class CategoriesDrawerComponent {
  constructor(private references: ReferencesService) {}

  // Список категорий
  mainPageCategoriesList = this.references.mainPageCategoriesList;
  common = signal(this.mainPageCategoriesList()?.common || []);
  allotted = signal(this.mainPageCategoriesList()?.allotted || []);

  isShowCategoriesDrawer = input.required<boolean>();

  closeDrawer = output();
  onChangeFunction() {
    this.closeDrawer.emit();
  }

  onSearch(search: string) {
    if (search) {
      this.allotted.set(
        this.mainPageCategoriesList()?.allotted.filter((category) =>
          category.name.toLowerCase().includes(search.toLowerCase()),
        ) || [],
      );

      this.common.set(
        this.mainPageCategoriesList()?.common.filter((category) =>
          category.name.toLowerCase().includes(search.toLowerCase()),
        ) || [],
      );
    } else {
      this.allotted.set(this.mainPageCategoriesList()?.allotted || []);
      this.common.set(this.mainPageCategoriesList()?.common || []);
    }
  }
}
