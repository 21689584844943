import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { RegistrationService } from '../../core/registration.service';
import { PasswordConfirmFormComponent } from '@components/common/password-confirm-form/password-confirm-form.component';
import { TCreatePasswordRequest } from '@models/commonTypes';

@Component({
  selector: 'app-set-password',
  imports: [FormsModule, PasswordConfirmFormComponent],
  templateUrl: './set-password.component.html',
  styleUrl: './set-password.component.scss',
})
export class SetPasswordComponent {
  error = this.registrationService.error;
  loading = this.registrationService.loading;

  constructor(private registrationService: RegistrationService) {}

  setPassword(data: TCreatePasswordRequest) {
    this.registrationService.setPassword(data);
  }
}
