@if (!opened()) {
  @if (showFullButton()) {
    <app-button
      class="width_button"
      [class.small]="!showFullButton()"
      [withIcon]="true"
      [height]="54"
      (mouseleave)="hovered.set(false)"
      (pressed)="clicked()"
    >
      <app-priority-icon />

      Приоритет отображения объявлений
    </app-button>
  } @else {
    <app-icon-button
      kind="priority"
      class="width_button"
      [class.small]="!showFullButton()"
      (pressed)="clicked()"
      (mouseenter)="hovered.set(true)"
      (focusin)="hovered.set(true)"
      (mouseleave)="hovered.set(false)"
      (focusout)="hovered.set(false)"
    />
  }
} @else {
  <div class="popup" [ngClass]="mobileDrawerMode() ? 'mobile' : 'desktop'">
    @if (!mobileDrawerMode()) {
      <div class="header">
        <div class="title">
          <app-priority-icon /> Приоритет отображения объявлений
        </div>
        <app-cross-icon class="cross" (click)="opened.set(false)" />
      </div>
    }
    <div class="priority-selected-list">
      <hr class="priority-hr" />
      @for (category of categoryList(); track category.code) {
        <!-- В категории есть культуры -->
        <mat-accordion *ngIf="category.cereals && category.cereals.length > 0">
          <mat-expansion-panel class="categories-panel" expanded="false">
            <mat-expansion-panel-header>
              <div class="item">
                <mat-checkbox
                  [checked]="category.status === 'selected'"
                  [indeterminate]="category.status === 'partly'"
                  (click)="
                    toggleCategoriesTreeEntity($event, {
                      categoryId: category.code,
                    })
                  "
                  [disabled]="categoryLoading() || defaultLoading()"
                  class="priority"
                >
                  <div class="checkbox-label">{{ category.label }}</div>
                </mat-checkbox>
              </div>
            </mat-expansion-panel-header>

            <hr class="priority-hr mb-0" />

            @if (category.cereals) {
              @for (
                cereal of category.cereals;
                let index = $index;
                track cereal.id
              ) {
                <!-- В культуре есть классификации -->
                <mat-accordion
                  *ngIf="
                    cereal.classifications && cereal.classifications.length > 0
                  "
                >
                  <mat-expansion-panel
                    class="cereals-panel"
                    [class.last-cereal]="index === category.cereals.length - 1"
                    expanded="false"
                  >
                    <mat-expansion-panel-header class="cereals-panel-header">
                      <div class="item cereal">
                        <mat-checkbox
                          [checked]="cereal.status === 'selected'"
                          [indeterminate]="cereal.status === 'partly'"
                          (click)="
                            toggleCategoriesTreeEntity($event, {
                              categoryId: category.code,
                              cerealId: cereal.id,
                            })
                          "
                          [disabled]="categoryLoading() || defaultLoading()"
                          class="priority"
                        >
                          <div class="checkbox-label">
                            {{ cereal.name }}
                          </div>
                        </mat-checkbox>
                      </div>
                    </mat-expansion-panel-header>

                    @if (cereal.classifications) {
                      @for (
                        classification of cereal.classifications;
                        track classification.id
                      ) {
                        <div class="item classification">
                          <mat-checkbox
                            [checked]="classification.status === 'selected'"
                            [indeterminate]="classification.status === 'partly'"
                            (click)="
                              toggleCategoriesTreeEntity($event, {
                                categoryId: category.code,
                                cerealId: cereal.id,
                                classificationId: classification.id,
                              })
                            "
                            [disabled]="categoryLoading() || defaultLoading()"
                            class="priority"
                          >
                            <div class="checkbox-label">
                              {{ classification.name }}
                            </div>
                          </mat-checkbox>
                        </div>
                      }
                    }
                  </mat-expansion-panel>
                </mat-accordion>

                <!-- В культуре нет классификаций -->
                <div
                  *ngIf="
                    !cereal.classifications || cereal.classifications.length < 1
                  "
                  class="item-wrapper cereal"
                >
                  <div class="item">
                    <mat-checkbox
                      [checked]="cereal.status === 'selected'"
                      [indeterminate]="cereal.status === 'partly'"
                      (click)="
                        toggleCategoriesTreeEntity($event, {
                          categoryId: category.code,
                          cerealId: cereal.id,
                        })
                      "
                      [disabled]="categoryLoading() || defaultLoading()"
                      class="priority"
                    >
                      <div class="checkbox-label">{{ cereal.name }}</div>
                    </mat-checkbox>
                  </div>
                </div>
              }
            }
          </mat-expansion-panel>
        </mat-accordion>

        <!-- В категории нет культур -->
        <div
          *ngIf="!category.cereals || category.cereals.length < 1"
          class="item-wrapper"
        >
          <div class="item">
            <mat-checkbox
              [checked]="category.status === 'selected'"
              [indeterminate]="category.status === 'partly'"
              (click)="
                toggleCategoriesTreeEntity($event, {
                  categoryId: category.code,
                })
              "
              [disabled]="categoryLoading() || defaultLoading()"
              class="priority"
            >
              <div class="checkbox-label">{{ category.label }}</div>
            </mat-checkbox>
          </div>
        </div>
      }
    </div>
    <div class="buttons">
      <app-button
        class="button"
        [withIcon]="true"
        width="stretch"
        (pressed)="applyFilter()"
        >Применить</app-button
      >
      <app-button
        class="button"
        [withIcon]="true"
        width="stretch"
        variety="grey"
        (pressed)="resetFilter()"
        >Сбросить</app-button
      >
    </div>
  </div>
}
