import { Component, computed, inject, signal, viewChild } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { NgIf, ViewportScroller } from '@angular/common';

import { RecaptchaComponent, RecaptchaModule } from 'ng-recaptcha-2';
import isEmpty from 'lodash-es/isEmpty';

import { InfoBlockComponent } from '@components/common/info-block/info-block.component';
import { ButtonComponent } from '@components/ui/button/button.component';
import { InputPasswordComponent } from '@components/ui/input-password/input-password.component';
import { TChangePasswordRequest } from '@models/profileTypes';
import { TFormattedApiError } from '@utils/formattingApiError';

import { NotificationService } from '@services/notification.service';
import { ProfileService } from '@services/profile.service';

import { environment } from '@environments/environment';

@Component({
  selector: 'app-safety',
  imports: [
    InfoBlockComponent,
    FormsModule,
    ButtonComponent,
    InputPasswordComponent,
    RecaptchaModule,
    NgIf,
  ],
  templateUrl: './safety.component.html',
  styleUrl: './safety.component.scss',
})
export class SafetyComponent {
  captcha = viewChild<RecaptchaComponent>('captcha');
  key = environment.captcha_key;

  profileService = inject(ProfileService);
  notification = inject(NotificationService);
  viewport = inject(ViewportScroller);

  loading = signal(false);
  apiError = signal<string | null>(null);
  passwordError = signal('');

  isNewPasswordValid = computed(
    () =>
      this.passwordMinLength() &&
      this.passwordHasDigits() &&
      this.passwordHasDifferentLetters(),
  );

  // Проверка сложности пароля
  passwordMinLength = signal(false);
  passwordHasDifferentLetters = signal(false);
  passwordHasDigits = signal(false);

  passwordChangeForm: TChangePasswordRequest = {
    password: '',
    new_password: '',
    confirm_new_password: '',
    recaptcha: null,
  };

  changePassword(newValue: string) {
    this.passwordMinLength.set(newValue.length >= 8);
    this.passwordHasDigits.set(/\d/.test(newValue));
    this.passwordHasDifferentLetters.set(
      /[A-Z]/.test(newValue) && /[a-z]/.test(newValue),
    );
  }

  resolved(captchaResponse: string | null) {
    this.passwordChangeForm.recaptcha = captchaResponse;
  }

  handleSubmit(form: NgForm) {
    this.loading.set(true);

    this.profileService.changePassword(this.passwordChangeForm).subscribe({
      next: () => {
        this.loading.set(false);
        this.apiError.set(null);
        this.passwordError.set('');
        this.captcha()?.reset();
        this.changePassword('');
        form.resetForm();

        this.notification.success('Пароль успешно изменен');
      },
      error: (error: TFormattedApiError) => {
        this.loading.set(false);
        this.captcha()?.reset();

        if (error.error_data && !isEmpty(error.error_data)) {
          const value = Object.entries(error.error_data).find(
            (item) => item[0] === 'password',
          );

          if (value) {
            this.apiError.set(null);
            this.passwordError.set(value[1]);
          } else {
            this.apiError.set(error.formattedErrorMessage);
            this.passwordError.set('');
          }
        } else {
          this.apiError.set(error.formattedErrorMessage);
          this.passwordError.set('');
        }

        this.viewport.scrollToPosition([0, 0]);
      },
    });
  }
}
