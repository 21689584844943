<div class="contact-block">
  <div class="label">
    @if (variety() === "email") {
      Email
    } @else {
      Телефоны для связи
    }
  </div>

  @if (variety() === "email") {
    <div class="contact-links-block">
      @for (email of contacts(); track email) {
        <div class="email">
          <img src="/assets/icons/email.svg" alt="Email" class="footer-icon" />
          <a href="mailto:{{ email }}" tabIndex="{0}" class="contact-link">
            {{ email }}
          </a>
        </div>
      }
    </div>
  }

  @if (variety() === "phone") {
    <div class="contact-links-block">
      @for (phonesRow of phones(); let i = $index; track i) {
        <div class="phones">
          <img src="/assets/icons/phone.svg" alt="Phone" class="footer-icon" />

          @for (phone of phonesRow; track phone; let index = $index) {
            <a href="tel:{{ phone }}" tabIndex="{0}" class="contact-link">
              {{ phone | phoneNumber }}
            </a>

            @if (phonesRow.length > 1 && index === 0) {
              <hr />
            }
          }
        </div>
      }
    </div>
  }
</div>
