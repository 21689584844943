@if (isMobile()) {
  <app-mobile-header class="header">
    <app-search-with-button
      placeholder="Поиск по объявлениям"
      (search)="searchAnnouncements($event)"
    ></app-search-with-button>
    <app-select-location></app-select-location>
  </app-mobile-header>
}

<!-- Промо-блок -->
@if (!isAuth()) {
  <app-promo />
}

@if (mainPageCategoriesList()) {
  <!-- Список категорий для мобильной версии -->
  <button
    class="categories-drawer-button"
    (click)="changeShowCategoriesDrawer()"
  >
    Выбрать категории
    <app-sprout-icon width="48px" height="48px"></app-sprout-icon>
  </button>
  <app-categories-drawer
    [isShowCategoriesDrawer]="isShowCategoriesDrawer()"
    (closeDrawer)="changeShowCategoriesDrawer()"
  />

  <!-- Список категорий для десктопной версии -->
  <div class="categories-list">
    <div class="common-categories-block">
      @for (category of mainPageCategoriesList()?.common; track category) {
        <app-category-link [category]="category" class="category-link" />
      }

      <!-- Блок для выравнивания категорий -->
      <div class="empty"></div>
    </div>

    <div class="allotted-categories-block">
      @for (category of mainPageCategoriesList()?.allotted; track category) {
        <app-category-link [category]="category" [allottedCategory]="true" />
      }
    </div>
  </div>
}

<div class="tabs">
  <a
    [routerLink]="[homeLink]"
    [queryParams]="{ type_value: 'sell' }"
    [ngClass]="{
      'tabs-link': true,
      left: true,
      active: announcementType === 'sell',
    }"
  >
    Продажа
  </a>
  <a
    [routerLink]="[homeLink]"
    [queryParams]="{ type_value: 'buy' }"
    [ngClass]="{
      'tabs-link': true,
      right: true,
      active: announcementType === 'buy',
    }"
  >
    Покупка
  </a>
</div>
@if (isAuth()) {
  @if (isMobile()) {
    <app-drawer
      [title]="
        'Приоритет отображения объявлений ' +
        (announcementType === 'sell' ? 'продажи' : 'покупки')
      "
      searchPlaceholder=""
      [opened]="isShowPriorityDrawer()"
      (closeClick)="isShowPriorityDrawer.set(false)"
    >
      <app-priority-button
        content
        [opened]="true"
        [mobileDrawerMode]="true"
        (updatedPriorities)="reloadAnnouncements()"
      />
    </app-drawer>
  }
  <app-priority-button
    class="priorities"
    [opened]="isShowPriorityDrawer()"
    (openModal)="isShowPriorityDrawer.set(true)"
    (updatedPriorities)="reloadAnnouncements()"
  />
}

<app-data-wrapper
  [isLoading]="isLoading()"
  [isEmpty]="!isLoading() && announcementsList().length < 1"
  emptyDescription="Список объявлений пуст"
>
  <div class="announcements-list">
    @for (announcement of listBeforeAdvertisement(); track announcement.slug) {
      <app-announcement-card
        (addingToFavourite)="changeAnnouncementIsFavourite($event)"
        [announcement]="announcement"
        [isFavouritesLoading]="isFavouritesLoading()"
        [isMyAnnouncement]="announcement.is_my"
        [class.disabled]="isFavouritesLoading()"
      ></app-announcement-card>
    }
  </div>
</app-data-wrapper>

<!-- <div class="advertising">
  <app-advertising-big-banner />
  <app-advertising-small-banner />
</div> -->

<!-- Продолжение списка после блока с рекламой -->
@if (listAfterAdvertisement().length > 0 && !isLoading()) {
  <div class="announcements-list second">
    @for (announcement of listAfterAdvertisement(); track announcement.slug) {
      <app-announcement-card
        (addingToFavourite)="changeAnnouncementIsFavourite($event)"
        [announcement]="announcement"
        [isFavouritesLoading]="isFavouritesLoading()"
        [isMyAnnouncement]="announcement.is_my"
        [class.disabled]="isFavouritesLoading()"
      ></app-announcement-card>
    }
  </div>
}

@if (isInfiniteLoading()) {
  <app-loader-global class="infinite-loader" [isInfinite]="true" />
}
