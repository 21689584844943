import { Component, computed, inject, input } from '@angular/core';

import { BaseChartDirective } from 'ng2-charts';
import { ChartData, ChartOptions } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { ru } from 'date-fns/locale';

import { TGraphPoint } from '../core/priceAnalysisTypes';
import { chartColors } from '@settings/chartColors';

import { UIService } from '@services/ui.service';

@Component({
  selector: 'app-price-charts',
  imports: [BaseChartDirective],
  templateUrl: './price-charts.component.html',
  styleUrl: './price-charts.component.scss',
})
export class PriceChartsComponent {
  uiService = inject(UIService);
  isMobile = this.uiService.isMobile;

  data = input.required<TGraphPoint[][]>();
  labels = input.required<string[]>();
  unit = input<'day' | 'week' | 'month'>('day');

  // Настройки графика
  chartOptions = computed<ChartOptions<'line'>>(() => ({
    elements: {
      line: {
        tension: 0.5,
      },
    },
    responsive: true /* график будет масштабирован */,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: this.unit(),
          round: 'day',
          tooltipFormat: 'dd.MM.yy',
          displayFormats: {
            day: 'dd.MM.yy',
            week: 'dd.MM.yy',
            month: 'MM.yy',
          },
        },
        adapters: {
          date: {
            locale: ru,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: !this.isMobile(),
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxWidth: 8,
          boxHeight: 8,
        },
      },
    },
  }));

  // Данные графика
  datasets = computed<ChartData<'line', TGraphPoint[]> | undefined>(() => {
    const dataCharts = this.data();
    const labelsCharts = this.labels();

    if (dataCharts && labelsCharts) {
      const data = dataCharts.map((item, index) => ({
        data: item,
        label: labelsCharts[index],
        pointBorderColor: chartColors[index % 10],
        pointBackgroundColor: chartColors[index % 10],
        borderColor: chartColors[index % 10],
        backgroundColor: chartColors[index % 10],
        parsing: {
          xAxisKey: 'period',
          yAxisKey: 'price',
        },
      }));

      return {
        datasets: data,
      };
    } else {
      return undefined;
    }
  });
}
