import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { MobileHeaderComponent } from '@components/common/mobile-header/mobile-header.component';
import { TabsComponent } from '@components/common/tabs/tabs.component';
import { TitleComponent } from '@components/common/title/title.component';
import { LinkBackComponent } from '@components/ui/link-back/link-back.component';
import { favouritesMenuList } from './core/favouritesMenuList';
import { FavouritesService } from './core/favourites.service';
import { ROUTE_PROFILE } from '@settings/routes';

import { UIService } from '@services/ui.service';

@Component({
  selector: 'app-favourites',
  imports: [
    TitleComponent,
    TabsComponent,
    RouterOutlet,
    MobileHeaderComponent,
    LinkBackComponent,
  ],
  templateUrl: './favourites.component.html',
  styleUrl: './favourites.component.scss',
})
export class FavouritesComponent {
  uiService = inject(UIService);
  favouritesService = inject(FavouritesService);

  isMobile = this.uiService.isMobile;
  tabList = favouritesMenuList;

  favouritesCounts = this.favouritesService.favouritesEntityCounts;

  routeProfile = `/${ROUTE_PROFILE}`;
}
