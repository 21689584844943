import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';

import { ROUTE_STATIC_PAGES } from '@settings/routes';

import { StaticPagesService } from '@components/pages/static-pages/core/static-pages.service';

@Component({
  selector: 'app-contacts-footer',
  imports: [RouterLink],
  templateUrl: './contacts-footer.component.html',
  styleUrl: './contacts-footer.component.scss',
})
export class ContactsFooterComponent {
  pagesService = inject(StaticPagesService);

  links = this.pagesService.pages;
  year = new Date().getFullYear();

  pagesRoute = ROUTE_STATIC_PAGES;
}
