import { Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgClass } from '@angular/common';

import { TMenuItem } from '@models/commonTypes';

@Component({
  selector: 'app-menu-link',
  imports: [RouterLink, NgClass],
  templateUrl: './menu-link.component.html',
  styleUrl: './menu-link.component.scss',
})
export class MenuLinkComponent {
  menuItem = input.required<TMenuItem<number>>();
  variety = input.required<'white' | 'black'>();
  adaptiveText = input<boolean>(false);
}
