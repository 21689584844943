<div class="profile-sidebar-component">
  <app-avatar />

  <div class="profile-sidebar-menu">
    @for (menuItem of menuList; track menuItem.id) {
      <a
        [routerLink]="[menuItem.link]"
        routerLinkActive="active"
        class="profile-sidebar-menu-item"
        >{{ menuItem.label }}
        <svg
          class="arrow"
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="22"
          viewBox="0 0 21 22"
          fill="none"
        >
          <path
            d="M7.875 16.25L13.125 11L7.875 5.75"
            stroke="#878787"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </a>
    }
  </div>

  <app-button-clipboard [value]="linkToProfile" [disabled]="!userId" />

  <app-button
    variety="grey"
    width="stretch"
    (pressed)="logOut()"
    class="logout-btn"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M14.5 8V6C14.5 5.46957 14.2893 4.96086 13.9142 4.58579C13.5391 4.21071 13.0304 4 12.5 4H5.5C4.96957 4 4.46086 4.21071 4.08579 4.58579C3.71071 4.96086 3.5 5.46957 3.5 6V18C3.5 18.5304 3.71071 19.0391 4.08579 19.4142C4.46086 19.7893 4.96957 20 5.5 20H12.5C13.0304 20 13.5391 19.7893 13.9142 19.4142C14.2893 19.0391 14.5 18.5304 14.5 18V16M9.5 12H21.5M21.5 12L18.5 9M21.5 12L18.5 15"
        stroke="#050609"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    Выйти
  </app-button>
</div>
