import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  PLATFORM_ID,
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-b24-crm-form',
  imports: [],
  templateUrl: './b24-crm-form.component.html',
  styleUrl: './b24-crm-form.component.scss',
})
export class B24CrmFormComponent implements AfterViewInit {
  document = inject(DOCUMENT);
  platformId = inject(PLATFORM_ID);
  elementRef = inject(ElementRef);

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const newScript = this.document.createElement('script');
      newScript.setAttribute('data-b24-form', 'inline/2/pnhmxx');
      newScript.setAttribute('data-skip-moving', 'true');
      newScript.innerHTML = `
      (function(w,d,u){
        var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0);
        var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
        })(window,document,'https://crm.zakroma.pro/upload/crm/form/loader_2_pnhmxx.js');`;

      this.elementRef.nativeElement.appendChild(newScript);
    }
  }
}
