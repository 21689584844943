import {
  Component,
  DestroyRef,
  OnDestroy,
  OnInit,
  afterNextRender,
  effect,
  inject,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { NgIf } from '@angular/common';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { distinctUntilChanged } from 'rxjs';

// import { MatCheckbox } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { SearchComponent } from '@components/ui/search/search.component';
import {
  TAnalyticsAddress,
  TAnalyticsCereal,
  TAnalyticsCerealClassification,
  TGroupBy,
  TViewBy,
} from '../core/priceAnalysisTypes';
import { TAnnouncementType } from '@models/announcementTypes';
import { ButtonComponent } from '@components/ui/button/button.component';
import { formattingDateForRequest, getPrevDate } from '@utils/formattingDate';
import { InfoBlockComponent } from '@components/common/info-block/info-block.component';
import { changeGroupList } from '../core/priceAnalysisUtils';
import { TFormattedApiError } from '@utils/formattingApiError';

import { PriceAnalyticsService } from '../core/price-analysis.service';
import { ReferencesService } from '@services/references.service';

@Component({
  selector: 'app-price-analysis-filter',
  imports: [
    MatSelectModule,
    MatRadioModule,
    MatDatepickerModule,
    SearchComponent,
    ReactiveFormsModule,
    MatIconModule,
    ButtonComponent,
    InfoBlockComponent,
    NgIf,
    MatExpansionModule,
    // MatCheckbox,
  ],
  templateUrl: './price-analysis-filter.component.html',
  styleUrl: './price-analysis-filter.component.scss',
})
export class PriceAnalysisFilterComponent implements OnInit, OnDestroy {
  addressSearch = viewChild<SearchComponent>('addressSearch');
  cerealSearch = viewChild<SearchComponent>('cerealSearch');

  // Исправление скролла вверх при выборе культуры в селекте
  cerealSelect = viewChild<MatSelect>('cerealSelect');
  private scrollTopBeforeSelection = 0;

  priceAnalyticsService = inject(PriceAnalyticsService);
  referencesService = inject(ReferencesService);
  destroyRef = inject(DestroyRef);

  categoriesList = this.referencesService.categoriesList;
  cerealsList = this.priceAnalyticsService.cerealsList;
  filterCerealsList = signal(this.cerealsList());

  cerealSelectedValues = signal('');

  addressList = this.priceAnalyticsService.addressList;
  filterAddressList = signal(this.addressList());

  viewBy = signal<TViewBy>('trader');

  loading = signal<boolean>(false);
  error = signal<string | null>(null);

  priceAnalysisGroupingList = signal<{ id: string; name: string }[]>([
    { id: 'day', name: 'Группировать по дням' },
    { id: 'week', name: 'Группировать по неделям' },
  ]);

  analyticsFilterForm: FormGroup = new FormGroup({
    category_code: new FormControl<string>('grain', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    category_cereal_classification_data: new FormControl<
      (TAnalyticsCerealClassification | TAnalyticsCereal)[]
    >([], {
      nonNullable: true,
      validators: [Validators.required],
    }),
    address_data: new FormControl<TAnalyticsAddress[]>([], {
      nonNullable: true,
      validators: [Validators.required],
    }),
    type: new FormControl<TAnnouncementType>('sell', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    start: new FormControl<Date>(getPrevDate(new Date()), {
      nonNullable: true,
      validators: [Validators.required],
    }),
    end: new FormControl<Date>(new Date(), {
      nonNullable: true,
      validators: [Validators.required],
    }),
    group_by: new FormControl<TGroupBy>('week', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  // Обновление списка культур при получении нового списка с бэка
  changeCereals = effect(() => {
    this.filterCerealsList.set(this.cerealsList());
  });

  // Обновление списка адресов при получении нового списка с бэка
  changeAddress = effect(() => {
    this.filterAddressList.set(this.addressList());
  });

  constructor() {
    afterNextRender(() => {
      this.priceAnalyticsService.loadAnalyticsCereals('grain');

      this.analyticsFilterForm.controls['category_code'].valueChanges
        .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
        .subscribe((categoryCode) => {
          if (categoryCode)
            this.priceAnalyticsService.loadAnalyticsCereals(categoryCode);

          if (categoryCode === 'grain_waste') {
            this.analyticsFilterForm.controls[
              'category_cereal_classification_data'
            ].removeValidators(Validators.required);
          } else {
            this.analyticsFilterForm.controls[
              'category_cereal_classification_data'
            ].addValidators(Validators.required);
          }

          this.analyticsFilterForm.controls[
            'category_cereal_classification_data'
          ].setValue(null);
          this.analyticsFilterForm.controls[
            'category_cereal_classification_data'
          ].updateValueAndValidity();
        });

      this.analyticsFilterForm.controls['start'].valueChanges
        .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
        .subscribe((start) => {
          this.priceAnalysisGroupingList.set(
            changeGroupList(start, this.analyticsFilterForm.value.end),
          );
          this.analyticsFilterForm.controls['group_by'].setValue(
            this.priceAnalysisGroupingList()[0].id,
          );
        });

      this.analyticsFilterForm.controls['end'].valueChanges
        .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
        .subscribe((end) => {
          this.priceAnalysisGroupingList.set(
            changeGroupList(this.analyticsFilterForm.value.start, end),
          );
          this.analyticsFilterForm.controls['group_by'].setValue(
            this.priceAnalysisGroupingList()[0].id,
          );
        });

      this.cerealSelect()
        ?.openedChange.pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((open) => {
          if (open) {
            this.cerealSelect()?.panel.nativeElement.addEventListener(
              'scroll',
              (event: Event & { target: HTMLElement }) =>
                (this.scrollTopBeforeSelection = event.target.scrollTop),
            );
          }
        });

      this.cerealSelect()
        ?.optionSelectionChanges.pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.cerealSelect()!.panel.nativeElement.scrollTop =
            this.scrollTopBeforeSelection;
        });
    });
  }

  ngOnInit() {
    this.analyticsFilterForm.controls[
      'category_cereal_classification_data'
    ].valueChanges
      .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        if (!data) {
          this.cerealSelectedValues.set('');
        } else {
          const title = data.map(
            (item: TAnalyticsCerealClassification | TAnalyticsCereal) => {
              if (
                Object.prototype.hasOwnProperty.call(item, 'classification_id')
              ) {
                const temp = this.cerealsList().find(
                  (category) => category.cereal_id === item.cereal_id,
                )?.label;

                return temp ? ' ' + temp + ' ' + item.label : ' ' + item.label;
              } else {
                return ' ' + item.label;
              }
            },
          );

          this.cerealSelectedValues.set(title);
        }
      });
  }

  ngOnDestroy() {
    this.resetForm();
    this.priceAnalyticsService.resetAnnouncementRecyclesCharts();
  }

  // Поиск в списке адресов
  onSearchAddress(search: string) {
    if (search) {
      this.filterAddressList.set(
        this.addressList().filter((address) =>
          address.label
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase()),
        ),
      );
    } else {
      this.filterAddressList.set(this.addressList());
    }
  }

  // Поиск в списке культур
  onSearchCereals(search: string) {
    if (search) {
      const cerealsTemp = this.cerealsList().filter((cereal) => {
        if (
          cereal.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        ) {
          return cereal;
        } else {
          const classificationsTemp = cereal.classifications.filter(
            (classification) =>
              classification.label
                .toLocaleLowerCase()
                .includes(search.toLocaleLowerCase()),
          );

          if (classificationsTemp.length > 0) {
            return {
              ...cereal,
              classification: classificationsTemp,
            };
          } else {
            return null;
          }
        }
      });

      this.filterCerealsList.set(cerealsTemp);
    } else {
      this.filterCerealsList.set(this.cerealsList());
    }
  }

  // Сброс поиска при закрытии селекта
  resetAddressSearch() {
    this.addressSearch()?.clear();
  }

  resetCerealSearch() {
    this.cerealSearch()?.clear();
  }

  deleteAddress(data: TAnalyticsAddress) {
    const newAddressList = this.analyticsFilterForm.value.address_data.filter(
      (item: TAnalyticsAddress) =>
        item.city_id !== data.city_id || item.region_id !== data.region_id,
    );

    this.analyticsFilterForm.controls['address_data'].setValue(newAddressList);
  }

  changeViewClick = output<TViewBy>();
  changeViewBy(value: TViewBy) {
    this.viewBy.set(value);
  }

  changeGroupBy = output<TGroupBy>();

  loadChartData() {
    let cerealClassification =
      this.analyticsFilterForm.value.category_cereal_classification_data;

    if (this.analyticsFilterForm.value.category_code === 'grain_waste') {
      cerealClassification = [
        {
          category_code: 'grain_waste',
        },
      ];
    } else {
      cerealClassification =
        this.analyticsFilterForm.value.category_cereal_classification_data.map(
          (item: TAnalyticsCerealClassification | TAnalyticsCereal) => {
            if (
              Object.prototype.hasOwnProperty.call(item, 'classification_id')
            ) {
              return {
                category_code: item.category_code,
                cereal_id: item.cereal_id,
                classification_id: (item as TAnalyticsCerealClassification)
                  .classification_id,
              };
            } else {
              return {
                category_code: item.category_code,
                cereal_id: item.cereal_id,
              };
            }
          },
        );
    }

    this.loading.set(true);

    this.priceAnalyticsService
      .loadAnalyticsAnnouncementRecycles({
        type: this.analyticsFilterForm.value.type,
        view_by: this.viewBy(),
        group_by: this.analyticsFilterForm.value.group_by,
        date_from:
          formattingDateForRequest(this.analyticsFilterForm.value.start) || '',
        date_to:
          formattingDateForRequest(this.analyticsFilterForm.value.end) || '',
        category_cereal_classification_data: cerealClassification,
        address_data: this.analyticsFilterForm.value.address_data,
      })
      .subscribe({
        next: (response) => {
          this.error.set(null);
          this.changeViewClick.emit(this.viewBy());
          this.changeGroupBy.emit(this.analyticsFilterForm.value.group_by);

          this.priceAnalyticsService.createAnalyticsRecyclesAnnouncementData(
            response,
          );

          this.loading.set(false);
        },
        error: (error: TFormattedApiError) => {
          if (error.formattedErrorMessage)
            this.error.set(error.formattedErrorMessage);

          this.loading.set(false);
        },
      });
  }

  resetForm() {
    this.analyticsFilterForm.reset();
  }
}
