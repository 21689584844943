import { Component, computed, input } from '@angular/core';

import { TPriceHistoryRecycles } from '../core/priceAnalysisTypes';
import { PricePipe } from '@pipes/price.pipe';

@Component({
  selector: 'app-price-history-recycles',
  imports: [PricePipe],
  templateUrl: './price-history-recycles.component.html',
  styleUrl: './price-history-recycles.component.scss',
})
export class PriceHistoryRecyclesComponent {
  orientation = input<'horizontally' | 'vertically'>('horizontally');
  title = input.required<string>();

  history = input.required<TPriceHistoryRecycles | undefined>();

  isNullData = computed(
    () =>
      this.history()?.max_price_with_vat === null &&
      this.history()?.max_price_without_vat === null &&
      this.history()?.min_price_with_vat === null &&
      this.history()?.min_price_without_vat === null,
  );
}
