<div class="contacts-page">
  <div class="contacts-page-main-block">
    @if (!isMobile()) {
      <app-header />
    } @else {
      <app-mobile-header variety="center">
        <app-title>Контакты</app-title>
      </app-mobile-header>
    }

    <div class="contacts-page-content">
      @if (!isMobile()) {
        <app-title class="title">Контакты</app-title>
      }

      @if (loading()) {
        <app-loader-global />
      } @else {
        @if (error()) {
          <app-info-block
            variety="error"
            [description]="error() || 'Неизвестная ошибка'"
          />
        }

        <div class="contacts">
          @if (phones().length > 0) {
            <div class="contact-block desktop">
              <div class="description">Телефоны для связи</div>

              @for (phonesRow of phones(); let i = $index; track i) {
                <div class="phones-row">
                  <img
                    src="/assets/icons/phone.svg"
                    alt="Phone"
                    class="footer-icon"
                  />

                  @for (phone of phonesRow; track phone; let index = $index) {
                    <a
                      href="tel:{{ phone }}"
                      tabIndex="{0}"
                      class="contact-link"
                    >
                      {{ phone | phoneNumber }}
                    </a>

                    @if (phonesRow.length > 1 && index === 0) {
                      <hr />
                    }
                  }
                </div>
              }
            </div>
          }

          @let adaptivePhones = contacts()?.phones;
          @if (adaptivePhones && adaptivePhones.length > 0) {
            <div class="contact-block adaptive">
              <div class="description">Телефоны для связи</div>

              @for (phone of adaptivePhones; track phone) {
                <div class="phones-row">
                  <img
                    src="/assets/icons/phone.svg"
                    alt="Phone"
                    class="footer-icon"
                  />

                  <a href="tel:{{ phone }}" tabIndex="{0}" class="contact-link">
                    {{ phone | phoneNumber }}
                  </a>
                </div>
              }
            </div>
          }

          @let emails = contacts()?.emails;
          @if (emails && emails.length > 0) {
            <div class="contact-block">
              <div class="description">Email</div>

              @for (email of emails; track email) {
                <div class="email">
                  <img
                    src="/assets/icons/email.svg"
                    alt="Email"
                    class="footer-icon"
                  />
                  <a
                    href="mailto:{{ email }}"
                    tabIndex="{0}"
                    class="contact-link"
                  >
                    {{ email }}
                  </a>
                </div>
              }
            </div>
          }

          @let socials = contacts()?.socials;
          @if (socials && socials.length > 0) {
            <div class="contact-block">
              <div class="socials">
                Будьте с нами на связи!

                <div class="socials-links">
                  @for (social of socials; track social) {
                    <a [href]="social.url" target="_blank">
                      <img [src]="social.icon" alt="icon" />
                    </a>
                  }
                </div>
              </div>
            </div>
          }
        </div>

        <div class="requisites">
          @if (contacts()?.requisites) {
            <app-requisites
              class="column"
              [requisites]="contacts()?.requisites"
            />
          }
          <app-b24-crm-form class="column" />
        </div>
      }
    </div>
  </div>

  @if (isMobile()) {
    <app-mobile-footer class="footer" />
  } @else {
    <app-contacts-footer />
  }
</div>
