import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'app-promo-image',
  imports: [NgClass],
  templateUrl: './promo-image.component.html',
  styleUrl: './promo-image.component.scss',
})
export class PromoImageComponent {
  size = input.required<'small' | 'middle' | 'large'>();
  image = input.required<string | null>();
}
