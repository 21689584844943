import { Component, signal } from '@angular/core';

import { PasswordRecoveryService } from '../../core/passwordRecovery.service';
import { TimerService } from '@services/timer.service';
import { ButtonComponent } from '@components/ui/button/button.component';
import { ButtonTextComponent } from '@components/ui/button-text/button-text.component';
import { InfoBlockComponent } from '@components/common/info-block/info-block.component';
import { PhoneNumberPipe } from '@pipes/phone-number.pipe';
import { InputConfirmationComponent } from '@components/common/input-confirmation/input-confirmation.component';
import { BackButtonComponent } from '@components/common/back-button/back-button.component';

@Component({
  selector: 'app-recovery-confirm-phone',
  imports: [
    ButtonComponent,
    ButtonTextComponent,
    InfoBlockComponent,
    PhoneNumberPipe,
    InputConfirmationComponent,
    BackButtonComponent,
  ],
  templateUrl: './recovery-confirm-phone.component.html',
  styleUrl: './recovery-confirm-phone.component.scss',
})
export class RecoveryConfirmPhoneComponent {
  constructor(
    private passwordRecoveryService: PasswordRecoveryService,
    private timerService: TimerService,
  ) {}

  code = '';
  isValidCode = signal(false);

  phoneNumber = this.passwordRecoveryService.phoneNumber;
  loading = this.passwordRecoveryService.loading;
  error = this.passwordRecoveryService.error;

  isGetCode = this.timerService.isCompletedTimer;
  timerValue = this.timerService.timerValue;

  changeIsValidCode(confirmCode: string) {
    this.isValidCode.set(!!confirmCode);
    this.code = confirmCode;
  }

  confirmPhone(event: Event) {
    event.preventDefault();

    this.passwordRecoveryService.recoveryConfirmPhone(this.code);
  }

  reSend() {
    this.passwordRecoveryService.recoveryReSendSms();
  }

  goBack() {
    this.passwordRecoveryService.resetError();
    this.passwordRecoveryService.setCurrentStep('set-phone-number');
  }
}
