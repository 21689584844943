import { Component, DestroyRef, OnInit, inject, signal } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import {
  ROUTE_FAVOURITES,
  ROUTE_HOME,
  ROUTE_MY_ANNOUNCEMENTS,
  ROUTE_PROFILE,
} from '@settings/routes';
import { AuthService } from '@services/auth.service';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';

@Component({
  selector: 'app-mobile-footer',
  imports: [MobileMenuComponent, RouterLink, RouterLinkActive],
  templateUrl: './mobile-footer.component.html',
  styleUrl: './mobile-footer.component.scss',
})
export class MobileFooterComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  homeLink = ROUTE_HOME;
  myAnnouncementsLink = `/${ROUTE_PROFILE}/${ROUTE_MY_ANNOUNCEMENTS}`;
  favouritesLink = `/${ROUTE_PROFILE}/${ROUTE_FAVOURITES}`;

  logOut() {
    this.authService.logOut();
  }

  destroyRef = inject(DestroyRef);
  isHomePage = signal(false);

  ngOnInit(): void {
    // Отслеживание нахождения на главной странице для установки активного стиля ссылки
    this.route.queryParams
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.isHomePage.set(this.router.url.startsWith('/?'));
      });
  }
}
