import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, DestroyRef, OnInit, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { LogoLinkComponent } from '@components/common/logo-link/logo-link.component';
import { NotNetworkComponent } from '@components/common/not-network/not-network.component';

import { UIService } from '@services/ui.service';

@Component({
  selector: 'app-external-layout',
  imports: [LogoLinkComponent, NotNetworkComponent],
  templateUrl: './external-layout.component.html',
  styleUrl: './external-layout.component.scss',
})
export class ExternalLayoutComponent implements OnInit {
  destroyRef = inject(DestroyRef);

  isAdaptiveLogo = signal(false);
  isNotNetwork = this.uiService.isNotNetwork;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private uiService: UIService,
  ) {}

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 1200px)'])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((state: BreakpointState) => {
        this.isAdaptiveLogo.set(state.breakpoints['(max-width: 1200px)']);
      });
  }
}
