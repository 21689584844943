import { Component, forwardRef, input, signal } from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'app-input-password',
  imports: [FormsModule],
  templateUrl: './input-password.component.html',
  styleUrl: './input-password.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputPasswordComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: InputPasswordComponent,
      multi: true,
    },
  ],
})
export class InputPasswordComponent implements ControlValueAccessor {
  value = '';

  name = input.required<string>();
  id = input.required<string>();

  disabled = input<boolean>(false);
  label = input<string>('');
  placeholder = input<string>('Пароль');
  autocomplete = input<AutoFill>('');
  error = input<boolean>(false);
  requiredLabel = input<boolean>(false);

  showPassword = signal(false);

  public onChangeFn = (_newValue: string) => {
    console.info(_newValue);
  };

  public onTouchedFn = () => {
    //
  };

  public registerOnChange(fn: VoidFunction): void {
    this.onChangeFn = fn;
  }

  public registerOnTouched(fn: VoidFunction): void {
    this.onTouchedFn = fn;
  }

  public writeValue(newValue: string): void {
    this.value = newValue;
  }

  public onChange() {
    this.onChangeFn(this.value);
  }

  validate() {
    //
  }

  toggleShowPassword(): void {
    this.showPassword.update((prev) => !prev);
  }
}
