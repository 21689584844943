import {
  Component,
  ElementRef,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ONLY_DECIMAL_DIGITS_PATTERN } from '@settings/validationSettings';

@Component({
  selector: 'app-block-number',
  imports: [FormsModule],
  templateUrl: './block-number.component.html',
  styleUrl: './block-number.component.scss',
})
export class BlockNumberComponent {
  input_number = viewChild<ElementRef>('input_number');

  value = '';
  changeValue = output<Event>();
  keyDown = output<KeyboardEvent>();
  touched = output();

  error = input<boolean>(false);
  disabled = input<boolean>(false);

  placeholder = signal('');

  onlyDecimal = ONLY_DECIMAL_DIGITS_PATTERN;

  setFocus() {
    setTimeout(() => this.input_number()?.nativeElement.focus());
  }

  handleFocus() {
    this.input_number()?.nativeElement.select();
    this.placeholder.set('_');
  }

  handleBlur() {
    this.placeholder.set('');
  }

  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Backspace') {
      event.preventDefault();

      this.value = '';
    }

    this.keyDown.emit(event);
  }
}
