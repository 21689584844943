import { Component, input } from '@angular/core';
import { NgClass } from '@angular/common';
import { RouterLink } from '@angular/router';

export type TIconButtonLinkKind = 'tg' | 'vk' | 'favorite';

@Component({
  selector: 'app-icon-button-link',
  imports: [NgClass, RouterLink],
  templateUrl: './icon-button-link.component.html',
  styleUrl: './icon-button-link.component.scss',
})
export class IconButtonLinkComponent {
  target = input<'_self' | '_blank'>('_self');
  link = input.required<string>();
  variety = input<'light' | 'dark'>('light');
  type = input<'button' | 'submit'>('button');
  kind = input.required<TIconButtonLinkKind>();
}
