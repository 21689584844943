import { Component, OnInit, afterNextRender, viewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { RecaptchaComponent, RecaptchaModule } from 'ng-recaptcha-2';

import { ExternalLayoutComponent } from '@components/layouts/external-layout/external-layout.component';
import { ROUTE_PASSWORD_RECOVERY, ROUTE_REGISTRATION } from '@settings/routes';
import { ErrorComponent } from '@components/common/error/error.component';
import { ButtonComponent } from '@components/ui/button/button.component';
import { LabelComponent } from '@components/ui/label/label.component';
import { LinkComponent } from '@components/ui/link/link.component';
import { AuthService } from '@services/auth.service';
import { TAuthFormData } from '@models/authTypes';
import { PHONE_PATTERN } from '@settings/validationSettings';
import { InputPasswordComponent } from '@components/ui/input-password/input-password.component';
import { InputPhoneComponent } from '@components/ui/input-phone/input-phone.component';

import { environment } from '@environments/environment';

@Component({
  selector: 'app-auth',
  imports: [
    ExternalLayoutComponent,
    ButtonComponent,
    LabelComponent,
    LinkComponent,
    FormsModule,
    ErrorComponent,
    InputPasswordComponent,
    InputPhoneComponent,
    RecaptchaModule,
  ],
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.scss',
})
export class AuthComponent implements OnInit {
  captcha = viewChild<RecaptchaComponent>('captcha');

  constructor(private authService: AuthService) {
    afterNextRender(() => {
      document.getElementById('root')?.scrollIntoView();
    });
  }

  registrationRoute = `/${ROUTE_REGISTRATION}`;
  passwordRecoveryRoute = `/${ROUTE_PASSWORD_RECOVERY}`;

  key = environment.captcha_key;

  error = this.authService.error;
  loading = this.authService.loading;

  loginForm: TAuthFormData = {
    phone_number: '',
    password: '',
    recaptcha: null,
  };

  phonePattern = PHONE_PATTERN;

  resolved(captchaResponse: string | null) {
    this.loginForm.recaptcha = captchaResponse;
  }

  signIn() {
    this.authService
      .logIn({
        phone_number: '+7' + this.loginForm.phone_number,
        password: this.loginForm.password,
        recaptcha: this.loginForm.recaptcha,
      })
      .subscribe({
        next: () => {
          this.captcha()?.reset();
        },
        error: () => {
          this.captcha()?.reset();
        },
      });
  }

  ngOnInit() {
    this.authService.setError(null);
  }
}
