import { Component } from '@angular/core';

@Component({
  selector: 'app-priority-icon',
  imports: [],
  styleUrl: './icons.component.scss',
  template: `<svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 12C5.46957 12 4.96086 11.7893 4.58579 11.4142C4.21071 11.0391 4 10.5304 4 10C4 9.46957 4.21071 8.96086 4.58579 8.58579C4.96086 8.21071 5.46957 8 6 8M6 12C6.53043 12 7.03914 11.7893 7.41421 11.4142C7.78929 11.0391 8 10.5304 8 10C8 9.46957 7.78929 8.96086 7.41421 8.58579C7.03914 8.21071 6.53043 8 6 8M6 12V20M6 8V4M13.9273 15.462C13.8271 15.1034 13.6288 14.7798 13.3547 14.5278C13.0806 14.2757 12.7416 14.1052 12.3758 14.0354C12.01 13.9657 11.632 13.9994 11.2844 14.1328C10.9368 14.2662 10.6333 14.494 10.4081 14.7906C10.1829 15.0872 10.045 15.4407 10.0099 15.8114C9.97478 16.1821 10.0438 16.5553 10.2093 16.8888C10.3748 17.2224 10.6301 17.5031 10.9465 17.6995C11.263 17.8958 11.6279 17.9999 12.0003 18L12 20M12 4V14M18 9C17.4696 9 16.9609 8.78929 16.5858 8.41421C16.2107 8.03914 16 7.53043 16 7C16 6.46957 16.2107 5.96086 16.5858 5.58579C16.9609 5.21071 17.4696 5 18 5M18 9C18.5304 9 19.0391 8.78929 19.4142 8.41421C19.7893 8.03914 20 7.53043 20 7C20 6.46957 19.7893 5.96086 19.4142 5.58579C19.0391 5.21071 18.5304 5 18 5M18 9V12M18 5V4M19 22V16M19 16L22 19M19 16L16 19"
      stroke="#050609"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg> `,
})
export class PriorityIconComponent {}
