import { differenceInDays } from 'date-fns';

export const changeGroupList = (startDate: Date, endDate: Date) => {
  const daysCount: number = differenceInDays(endDate, startDate);

  if (daysCount < 7) {
    return [{ id: 'day', name: 'Группировать по дням' }];
  }

  if (
    endDate < new Date(new Date(startDate).setMonth(startDate.getMonth() + 1))
  ) {
    return [
      { id: 'day', name: 'Группировать по дням' },
      { id: 'week', name: 'Группировать по неделям' },
    ];
  }

  if (
    endDate < new Date(new Date(startDate).setMonth(startDate.getMonth() + 3))
  ) {
    return [
      { id: 'day', name: 'Группировать по дням' },
      { id: 'week', name: 'Группировать по неделям' },
      { id: 'month', name: 'Группировать по месяцам' },
    ];
  }

  if (
    endDate <
    new Date(new Date(startDate).setFullYear(startDate.getFullYear() + 1))
  ) {
    return [
      { id: 'week', name: 'Группировать по неделям' },
      { id: 'month', name: 'Группировать по месяцам' },
    ];
  }

  return [{ id: 'month', name: 'Группировать по месяцам' }];
};
