import { Component, signal } from '@angular/core';

import { InputConfirmationComponent } from '@components/common/input-confirmation/input-confirmation.component';
import { ButtonComponent } from '@components/ui/button/button.component';
import { RegistrationService } from '../../core/registration.service';
import { ButtonTextComponent } from '@components/ui/button-text/button-text.component';
import { ErrorComponent } from '@components/common/error/error.component';
import { PhoneNumberPipe } from '@pipes/phone-number.pipe';
import { TimerService } from '@services/timer.service';
import { BackButtonComponent } from '@components/common/back-button/back-button.component';

@Component({
  selector: 'app-confirm-phone',
  imports: [
    InputConfirmationComponent,
    ErrorComponent,
    ButtonComponent,
    ButtonTextComponent,
    PhoneNumberPipe,
    BackButtonComponent,
  ],
  templateUrl: './confirm-phone.component.html',
  styleUrl: './confirm-phone.component.scss',
})
export class ConfirmPhoneComponent {
  constructor(
    private registrationService: RegistrationService,
    private timerService: TimerService,
  ) {}

  code = '';
  isValidCode = signal(false);

  phoneNumber = this.registrationService.phoneNumber;
  loading = this.registrationService.loading;
  error = this.registrationService.error;

  isGetCode = this.timerService.isCompletedTimer;
  timerValue = this.timerService.timerValue;

  changeIsValidCode(confirmCode: string) {
    this.isValidCode.set(!!confirmCode);
    this.code = confirmCode;
  }

  confirmPhone(event: Event) {
    event.preventDefault();

    this.registrationService.confirmPhone(this.code);
  }

  reSend() {
    this.registrationService.reSendSms();
  }

  goBack() {
    this.registrationService.resetError();
    this.registrationService.setCurrentStep('set-phone-number');
  }
}
