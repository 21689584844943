import { Component } from '@angular/core';

import { promoItemsFirstList, promoItemsSecondList } from './promoItemsList';
import { PromoImageComponent } from './promo-image/promo-image.component';

@Component({
  selector: 'app-promo',
  imports: [PromoImageComponent],
  templateUrl: './promo.component.html',
  styleUrl: './promo.component.scss',
})
export class PromoComponent {
  itemsFist = promoItemsFirstList;
  itemsSecond = promoItemsSecondList;

  // todo Добавить ссылку
  trialLink = '/';
}
