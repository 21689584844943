import { Component } from '@angular/core';

import { InfoBlockComponent } from '@components/common/info-block/info-block.component';

@Component({
  selector: 'app-confirm-email',
  imports: [InfoBlockComponent],
  templateUrl: './confirm-email.component.html',
  styleUrl: './confirm-email.component.scss',
})
export class ConfirmEmailComponent {}
