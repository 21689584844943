import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  PLATFORM_ID,
  signal,
  viewChild,
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { EnvelopIconComponent } from '@components/ui/icons/envelop-icon.component';

@Component({
  selector: 'app-b24-crm-button',
  imports: [EnvelopIconComponent],
  templateUrl: './b24-crm-button.component.html',
  styleUrl: './b24-crm-button.component.scss',
  host: {
    '[class.spread]': 'hovered()',
    '(mouseenter)': 'this.hovered.set(true)',
    '(focusin)': 'this.hovered.set(true)',
    '(mouseleave)': 'this.hovered.set(false)',
    '(focusout)': 'this.hovered.set(false)',
  },
})
export class B24CrmButtonComponent implements AfterViewInit {
  document = inject(DOCUMENT);
  platformId = inject(PLATFORM_ID);

  button = viewChild.required<ElementRef>('button');
  hovered = signal(false);

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const newScript = this.document.createElement('script');
      newScript.setAttribute('data-b24-form', 'click/2/pnhmxx');
      newScript.setAttribute('data-skip-moving', 'true');
      newScript.innerHTML = `(function(w,d,u){
        var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0);
        var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
        })(window,document,'https://crm.zakroma.pro/upload/crm/form/loader_2_pnhmxx.js');`;

      this.button().nativeElement.insertBefore(
        newScript,
        this.button().nativeElement.firstChild,
      );
    }
  }
}
