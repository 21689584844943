import {
  Component,
  DestroyRef,
  afterNextRender,
  computed,
  inject,
  signal,
} from '@angular/core';
import { provideNativeDateAdapter } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { PriceChartsComponent } from './price-charts/price-charts.component';
import { LoaderGlobalComponent } from '@components/common/loader-global/loader-global.component';
import { MobileHeaderComponent } from '@components/common/mobile-header/mobile-header.component';
import { TitleComponent } from '@components/common/title/title.component';
import { LinkBackComponent } from '@components/ui/link-back/link-back.component';
import { PriceHistoryComponent } from './price-history/price-history.component';
import { PriceHistoryRecyclesComponent } from './price-history-recycles/price-history-recycles.component';
import { PriceAnalysisFilterComponent } from './price-analysis-filter/price-analysis-filter.component';
import { TGraphPoint, TGroupBy, TViewBy } from './core/priceAnalysisTypes';
import { EmptyListComponent } from '@components/common/empty-list/empty-list.component';

import { AuthService } from '@services/auth.service';
import { UIService } from '@services/ui.service';
import { PriceAnalyticsService } from './core/price-analysis.service';

@Component({
  selector: 'app-price-analysis',
  providers: [provideNativeDateAdapter()],
  imports: [
    LoaderGlobalComponent,
    MobileHeaderComponent,
    LinkBackComponent,
    TitleComponent,
    PriceHistoryComponent,
    PriceHistoryRecyclesComponent,
    PriceAnalysisFilterComponent,
    PriceChartsComponent,
    EmptyListComponent,
  ],
  templateUrl: './price-analysis.component.html',
  styleUrl: './price-analysis.component.scss',
})
export class PriceAnalysisComponent {
  authService = inject(AuthService);
  uiService = inject(UIService);
  route = inject(ActivatedRoute);
  router = inject(Router);
  location = inject(Location);
  destroyRef = inject(DestroyRef);
  priceAnalysisService = inject(PriceAnalyticsService);

  canGoBack = false;

  viewBy = signal<string>('trader');
  groupBy = signal<TGroupBy>('week');

  historyTitle = computed(() => {
    if (this.viewBy() === 'mixed') return 'Сравнение рыночных цен';

    if (this.viewBy() === 'recycler') return 'Цены от зернообработчиков';

    return 'Цены объявлений';
  });
  isHistoryNull = computed<boolean>(() => {
    const announcementPriceHistory =
      this.priceAnalysisService.charts()?.announcements_price_history;
    const recyclersPriceHistory =
      this.priceAnalysisService.charts()?.recyclers_price_history;

    if (this.viewBy() === 'mixed')
      return (
        announcementPriceHistory?.avg === null &&
        announcementPriceHistory.max === null &&
        announcementPriceHistory.min === null &&
        recyclersPriceHistory?.avg_price_with_vat === null &&
        recyclersPriceHistory.avg_price_without_vat === null &&
        recyclersPriceHistory.max_price_with_vat === null &&
        recyclersPriceHistory.max_price_without_vat === null &&
        recyclersPriceHistory.min_price_with_vat === null &&
        recyclersPriceHistory.min_price_without_vat === null
      );

    if (this.viewBy() === 'recycler')
      return (
        recyclersPriceHistory?.avg_price_with_vat === null &&
        recyclersPriceHistory.avg_price_without_vat === null &&
        recyclersPriceHistory.max_price_with_vat === null &&
        recyclersPriceHistory.max_price_without_vat === null &&
        recyclersPriceHistory.min_price_with_vat === null &&
        recyclersPriceHistory.min_price_without_vat === null
      );

    return (
      announcementPriceHistory?.avg === null &&
      announcementPriceHistory.max === null &&
      announcementPriceHistory.min === null
    );
  });

  // Данные для графиков
  data = computed<TGraphPoint[][] | undefined>(() => {
    if (this.viewBy() === 'mixed') {
      const announcementCharts =
        this.priceAnalysisService
          .charts()
          ?.announcements_graphs.map((item) => item.points) || [];

      const recyclesCharts =
        this.priceAnalysisService
          .charts()
          ?.recyclers_graphs.map((item) => item.points) || [];

      return [...announcementCharts, ...recyclesCharts];
    }

    if (this.viewBy() === 'recycler')
      return this.priceAnalysisService
        .charts()
        ?.recyclers_graphs.map((item) => item.points);

    return this.priceAnalysisService
      .charts()
      ?.announcements_graphs.map((item) => item.points);
  });
  labels = computed<string[] | undefined>(() => {
    if (this.viewBy() === 'mixed') {
      const announcementCharts =
        this.priceAnalysisService
          .charts()
          ?.announcements_graphs.map((item) => item.title) || [];

      const recyclesCharts =
        this.priceAnalysisService
          .charts()
          ?.recyclers_graphs.map((item) => item.title) || [];

      return [...announcementCharts, ...recyclesCharts];
    }

    if (this.viewBy() === 'recycler')
      return this.priceAnalysisService
        .charts()
        ?.recyclers_graphs.map((item) => item.title);

    return this.priceAnalysisService
      .charts()
      ?.announcements_graphs.map((item) => item.title);
  });

  charts = this.priceAnalysisService.charts;

  loading = computed(
    () => this.authService.loading() || this.authService.loadingAuthData(),
  );
  isMobile = this.uiService.isMobile;

  constructor() {
    this.canGoBack = !!this.router.getCurrentNavigation()?.previousNavigation;

    afterNextRender(() => {
      this.priceAnalysisService.loadAnalyticsAddress();
    });
  }

  changeViewBy(value: TViewBy) {
    this.viewBy.set(value);
  }

  changeGroupBy(value: TGroupBy) {
    this.groupBy.set(value);
  }

  goBack() {
    if (this.canGoBack) {
      this.location.back();
    } else {
      this.router.navigate(['/']);
    }
  }
}
