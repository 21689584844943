import { inject, PLATFORM_ID } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { catchError, map, of } from 'rxjs';

import { AuthService } from '@services/auth.service';
import { StorageService } from '@services/storage.sevice';
import { STORAGE_REDIRECT_PATH } from '@settings/constants';
import { ROUTE_AUTH } from '@settings/routes';

export const authGuard: CanActivateFn = (_route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const storage = inject(StorageService);
  const platformId = inject(PLATFORM_ID);

  if (isPlatformBrowser(platformId)) {
    if (authService.isAuth()) return true;

    // сохраним когда неавторизованный кликнул на ссылку
    if (state.url !== '/' + ROUTE_AUTH) {
      storage.setItem(STORAGE_REDIRECT_PATH, state.url);
    }
    authService.setRedirectPathRecorded(true);

    return authService.checkAuthRequest().pipe(
      catchError(() => {
        if (state.url !== '/' + ROUTE_AUTH) {
          storage.setItem(STORAGE_REDIRECT_PATH, state.url);
          authService.setRedirectPathRecorded(true);
        }
        void router.navigate([ROUTE_AUTH]);
        return of(false);
      }),
      map(() => true),
    );
  }

  // с сервера разрешаем
  return true;
};
