import { inject, PLATFORM_ID } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { ROUTE_HOME } from '@settings/routes';
import { AuthService } from '@services/auth.service';
import { isPlatformBrowser } from '@angular/common';
import { catchError, map, of } from 'rxjs';

export const nonAuthGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const platformId = inject(PLATFORM_ID);

  if (isPlatformBrowser(platformId)) {
    if (authService.isAuth()) return false;

    authService.setIgnoreThis401(true);

    return authService.checkAuthRequest().pipe(
      catchError(() => {
        authService.setIgnoreThis401(false);
        return of(false); // отдаём дальше false
      }),
      map((profile) => {
        authService.setIgnoreThis401(false);

        if (false === profile) {
          // не авторизованы, можно
          return true;
        }

        // профайл есть, значит нельзя, на главную
        void router.navigate([ROUTE_HOME]);
        return false;
      }),
    );
  }

  // с сервера разрешаем
  return true;
};
