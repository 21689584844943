import {
  Component,
  OnInit,
  input,
  output,
  signal,
  viewChildren,
} from '@angular/core';
import { BlockNumberComponent } from './block-number/block-number.component';
import { CONFIFM_CODE_LENGTH } from '@settings/constants';

@Component({
  selector: 'app-input-confirmation',
  imports: [BlockNumberComponent],
  templateUrl: './input-confirmation.component.html',
  styleUrl: './input-confirmation.component.scss',
})
export class InputConfirmationComponent implements OnInit {
  code = signal<string[]>(['', '', '', '', '', '']);
  codeLength = CONFIFM_CODE_LENGTH;

  numberBlocks = viewChildren<BlockNumberComponent>(BlockNumberComponent);

  error = input<boolean>(false);
  disabled = input<boolean>(false);

  confirmCode = output<string>();

  changeValue(id: number, value: string, event?: Event) {
    this.code.update((prev) => {
      const newArray = [...prev];

      if (value) {
        newArray[id] = value;
      } else {
        newArray[id] = event
          ? (event.target as HTMLInputElement).value.slice(-1)
          : '';
      }

      return newArray;
    });
  }

  handlePaste(event: ClipboardEvent) {
    event.preventDefault();
    event.stopPropagation();

    const clipboardData = event.clipboardData;
    const pasteText = clipboardData?.getData('Text');

    if (pasteText && pasteText.length === this.codeLength) {
      const chars = Array.from(pasteText);

      chars.forEach((char, i) => {
        this.numberBlocks()[i].value = char;
        this.handleChange(i, char);
      });
    }
  }

  handleInput(value: string) {
    const chars = Array.from(value);

    chars.forEach((char, i) => {
      this.numberBlocks()[i].value = char;
      this.handleChange(i, char);
    });
  }

  // Изменение кода и переход к следующему полю
  handleChange = (id: number, value: string, event?: Event) => {
    if (
      event &&
      (event.target as HTMLInputElement).value.length === this.codeLength
    ) {
      this.handleInput((event.target as HTMLInputElement).value);
    } else {
      this.changeValue(id, value, event);

      if (id < this.codeLength - 1) {
        this.numberBlocks()[id + 1].setFocus();
      } else {
        if (id === this.codeLength - 1) {
          this.numberBlocks()[0].setFocus();
        }
      }

      // Отправка кода в форму
      this.outputConfirmCode();
    }
  };

  handleKeyDown = (id: number, event: KeyboardEvent) => {
    if (event.key === 'Backspace') {
      event.preventDefault();
      event.stopPropagation();

      this.changeValue(id, '');
      this.outputConfirmCode();
      if (id > 0) this.numberBlocks()[id - 1].setFocus();
    }

    if (event.key === 'ArrowLeft' && id > 0) {
      this.numberBlocks()[id - 1].setFocus();
    }

    if (event.key === 'ArrowRight' && id < this.codeLength - 1) {
      this.numberBlocks()[id + 1].setFocus();
    }
  };

  outputConfirmCode() {
    const stringCode = this.code()
      .filter((item) => Number(item) >= 0 && Number(item) < 10)
      .join('');

    this.confirmCode.emit(
      stringCode.length === this.codeLength ? stringCode : '',
    );
  }

  ngOnInit() {
    if (this.numberBlocks()[0]) this.numberBlocks()[0].setFocus();
  }
}
