import { Component, computed, input, output, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { InfoBlockComponent } from '../info-block/info-block.component';
import { TFormattedApiError } from '@utils/formattingApiError';
import { ButtonComponent } from '@components/ui/button/button.component';
import { InputPasswordComponent } from '@components/ui/input-password/input-password.component';
import { TCreatePasswordRequest } from '@models/commonTypes';

@Component({
  selector: 'app-password-confirm-form',
  imports: [
    InfoBlockComponent,
    FormsModule,
    ButtonComponent,
    InputPasswordComponent,
  ],
  templateUrl: './password-confirm-form.component.html',
  styleUrl: './password-confirm-form.component.scss',
})
export class PasswordConfirmFormComponent {
  formSubmit = output<TCreatePasswordRequest>();

  title = input.required<string>();
  apiError = input<TFormattedApiError | null>(null);
  disabled = input<boolean>(false);

  isPasswordValid = computed(
    () =>
      this.passwordMinLength() &&
      this.passwordHasDigits() &&
      this.passwordHasDifferentLetters(),
  );

  // Проверка сложности пароля
  passwordMinLength = signal(false);
  passwordHasDifferentLetters = signal(false);
  passwordHasDigits = signal(false);

  passwordCreateForm: TCreatePasswordRequest = {
    password: '',
    password_confirm: '',
  };

  changePassword(newValue: string) {
    this.passwordMinLength.set(newValue.length >= 8);
    this.passwordHasDigits.set(/\d/.test(newValue));
    this.passwordHasDifferentLetters.set(
      /[A-Z]/.test(newValue) && /[a-z]/.test(newValue),
    );
  }

  handleSubmit(event: Event) {
    event.preventDefault();

    this.formSubmit.emit(this.passwordCreateForm);
  }
}
