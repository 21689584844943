import { Component, input } from '@angular/core';

import { LogoLinkComponent } from '../logo-link/logo-link.component';
import { ROUTE_HOME } from '@settings/routes';

@Component({
  selector: 'app-not-network',
  imports: [LogoLinkComponent],
  templateUrl: './not-network.component.html',
  styleUrl: './not-network.component.scss',
})
export class NotNetworkComponent {
  isExternalLayout = input(false);

  homeLink = ROUTE_HOME;
}
