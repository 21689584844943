import { Component } from '@angular/core';

import { LinkComponent } from '@components/ui/link/link.component';
import { ROUTE_AUTH } from '@settings/routes';

@Component({
  selector: 'app-recovery-password-changed',
  imports: [LinkComponent],
  templateUrl: './recovery-password-changed.component.html',
  styleUrl: './recovery-password-changed.component.scss',
})
export class RecoveryPasswordChangedComponent {
  authRoute = `/${ROUTE_AUTH}`;
}
