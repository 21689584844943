import { Component } from '@angular/core';

@Component({
  selector: 'app-envelop-icon',
  imports: [],
  template: ` <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="26"
    viewBox="0 0 28 26"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.66536 4.54169C2.89345 4.54169 1.45703 5.97811 1.45703 7.75002V19.4167C1.45703 21.1886 2.89345 22.625 4.66536 22.625H23.332C25.104 22.625 26.5404 21.1886 26.5404 19.4167V7.75002C26.5404 5.97811 25.104 4.54169 23.332 4.54169H4.66536ZM8.66715 9.36653C8.27125 9.0894 7.72566 9.18568 7.44854 9.58157C7.17141 9.97747 7.2677 10.5231 7.66358 10.8002L13.4969 14.8835C13.7981 15.0944 14.1992 15.0944 14.5005 14.8835L20.3338 10.8002C20.7297 10.5231 20.826 9.97747 20.5488 9.58157C20.2717 9.18568 19.7261 9.0894 19.3302 9.36653L13.9987 13.0986L8.66715 9.36653Z"
      fill="#2C2C2C"
    />
  </svg>`,
})
export class EnvelopIconComponent {}
