<app-back-link [link]="authRoute" />

<form name="set-phone-form" (submit)="setPhone($event)" class="set-phone-form">
  <div class="title mb-16">Регистрация</div>

  <app-label label="Введите свой номер телефона" />

  @if (!!error()) {
    <app-error
      [errorText]="
        error()?.formattedErrorMessage || 'Укажите корректный номер телефона'
      "
    />
  }

  <app-input-phone
    name="phone-number"
    id="phone-number"
    #phone_number="ngModel"
    [(ngModel)]="setPhoneForm.phone_number"
    [required]="true"
    [pattern]="phonePattern"
    [disabled]="loading()"
    [error]="(phone_number.invalid && phone_number.touched) || !!error()"
  />

  @if (pages() && 0 < pages().length) {
    <div class="label mt-16">
      Продолжая, я соглашаюсь с условиями

      @for (page of pages(); track page.url; let last = $last) {
        @if (!last) {
          <a
            href="{{ basePagesUrl + page.url }}"
            target="_blank"
            class="link"
            >{{ page.name }}</a
          >
          и
        } @else {
          <a
            href="{{ basePagesUrl + page.url }}"
            target="_blank"
            class="link"
            >{{ page.name }}</a
          >
        }
      }

      и даю согласие на обработку моих персональных данных.
    </div>
  }

  <re-captcha
    #captcha
    (resolved)="resolved($event)"
    [siteKey]="key"
    class="recaptcha-block"
  ></re-captcha>

  <app-button
    variety="secondary"
    width="stretch"
    type="submit"
    class="submit-button mb-24"
    [disabled]="
      !!phone_number.invalid || loading() || setPhoneForm.recaptcha === null
    "
    >Далее</app-button
  >
</form>
