<div class="price-analysis-page">
  @if (loading()) {
    <app-loader-global class="loader" />
  } @else {
    @if (isMobile()) {
      <app-mobile-header variety="center">
        @if (canGoBack) {
          <app-link-back (click)="goBack()" variety="absolute" />
        }

        <app-title>Аналитика цен</app-title>
      </app-mobile-header>
    } @else {
      <app-title class="title">Аналитика цен</app-title>
    }

    <app-price-analysis-filter
      (changeViewClick)="changeViewBy($event)"
      (changeGroupBy)="changeGroupBy($event)"
    />

    <div class="subtitle">{{ historyTitle() }}</div>
    @if (priceAnalysisService.charts()) {
      <!-- Если есть что-то в истории цен -->
      @if (!isHistoryNull()) {
        <div class="histories">
          @if (viewBy() === "trader" || viewBy() === "") {
            <app-price-history
              variety="announcements"
              title="Средняя цена"
              [history]="
                priceAnalysisService.charts()?.announcements_price_history
              "
            />
          }

          @if (viewBy() === "recycler") {
            <app-price-history-recycles
              variety="recycles"
              title="Средняя цена"
              [history]="priceAnalysisService.charts()?.recyclers_price_history"
            />
          }

          @if (viewBy() === "mixed") {
            <app-price-history
              variety="announcements"
              orientation="vertically"
              title="Средняя цена по объявлениям"
              [history]="
                priceAnalysisService.charts()?.announcements_price_history
              "
            />
            <app-price-history-recycles
              variety="recycles"
              orientation="vertically"
              title="Средняя цена от зернообработчиков"
              [history]="priceAnalysisService.charts()?.recyclers_price_history"
            />
          }
        </div>
      }

      @if (data() && data()?.length) {
        <div class="charts-view">
          <app-price-charts
            [data]="data() || []"
            [labels]="labels() || []"
            [unit]="groupBy()"
            class="charts-wrapper"
          />
        </div>
      } @else {
        <app-empty-list
          description="Для указанных параметров недостаточно аналитических данных"
        />
      }
    } @else {
      <div class="preview-wrapper">
        <div class="preview-label">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="26"
            viewBox="0 0 24 26"
            fill="none"
          >
            <path
              d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2Z"
              stroke="#050609"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12 16V12"
              stroke="#050609"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12 8H11.99"
              stroke="#050609"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          Выберите все параметры, чтобы увидеть график
        </div>
        <img src="/assets/chart-preview.png" alt="Графики" />
      </div>
    }
  }
</div>
