import {
  Component,
  DestroyRef,
  afterNextRender,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
  throttleTime,
} from 'rxjs';

import { TitleComponent } from '@components/common/title/title.component';
import { MobileHeaderComponent } from '@components/common/mobile-header/mobile-header.component';
import { LinkBackComponent } from '@components/ui/link-back/link-back.component';
import { TChangeFavourites } from '@components/pages/home/core/announcementsListTypes';
import { DataWrapperComponent } from '@components/common/data-wrapper/data-wrapper.component';
import { InfoBlockComponent } from '@components/common/info-block/info-block.component';
import { LoaderGlobalComponent } from '@components/common/loader-global/loader-global.component';
import { AnnouncementCardComponent } from '@components/pages/home/announcement-card/announcement-card.component';
import { TFormattedApiError } from '@utils/formattingApiError';
import { ROUTE_PROFILE } from '@settings/routes';

import { UIService } from '@services/ui.service';
import { OpenContactsService } from './core/openContacts.service';
import { NotificationService } from '@services/notification.service';
import { FavouritesService } from '../favourites/core/favourites.service';

@Component({
  selector: 'app-open-contacts',
  imports: [
    DataWrapperComponent,
    LoaderGlobalComponent,
    InfoBlockComponent,
    AnnouncementCardComponent,
    TitleComponent,
    MobileHeaderComponent,
    LinkBackComponent,
  ],
  templateUrl: './open-contacts.component.html',
  styleUrl: './open-contacts.component.scss',
})
export class OpenContactsComponent {
  uiService = inject(UIService);
  openContactsService = inject(OpenContactsService);
  favouritesService = inject(FavouritesService);
  notificationService = inject(NotificationService);

  isMobile = this.uiService.isMobile;

  openContactsList = this.openContactsService.openContactsList;

  isLoading = this.openContactsService.loader;
  isInfiniteLoading = this.openContactsService.infiniteLoader;
  isFavouritesLoading = signal(false);
  error = this.openContactsService.error;

  destroyRef = inject(DestroyRef);

  routeProfile = `/${ROUTE_PROFILE}`;

  constructor() {
    afterNextRender(() => {
      document.getElementById('root')?.scrollIntoView();

      this.openContactsService.resetOpenContactsData();
      this.openContactsService.loadOpenContactsAnnouncements({});

      // Высота footer или мобильного footer + половина высоты карточки объявления
      const footerHeight =
        document.getElementById('footer')?.clientHeight || 70;

      const pageByScroll$ = fromEvent(window, 'scroll', { passive: true }).pipe(
        map(() => window.scrollY),
        filter(
          (scrollY) =>
            scrollY >=
            document.documentElement.scrollHeight -
              window.innerHeight -
              footerHeight -
              210,
        ),
        throttleTime(200),
        distinctUntilChanged(),
      );

      pageByScroll$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        if (!this.isLoading() && !this.isInfiniteLoading())
          this.openContactsService.loadOpenContactsAnnouncements({
            isInfinite: true,
          });
      });
    });
  }

  changeAnnouncementIsFavourite({ slug, is_favorite }: TChangeFavourites) {
    this.isFavouritesLoading.set(true);

    const changeFunction = !is_favorite
      ? this.favouritesService.addingAnnouncementToFavorites(slug)
      : this.favouritesService.deletingAnnouncementFromFavorites(slug);

    changeFunction.subscribe({
      next: () => {
        this.openContactsService.changeAnnouncementIsFavorite(slug);

        this.isFavouritesLoading.set(false);
      },
      error: (error: TFormattedApiError) => {
        this.isFavouritesLoading.set(false);

        if (error.formattedErrorMessage)
          this.notificationService.error(error.formattedErrorMessage);
      },
    });
  }
}
