<div class="technical-information-block">
  <div class="technical-information">
    © {{ year }} Закрома, все права защищены
  </div>

  <div class="technical-links-block">
    @for (link of links(); track link.url) {
      <a
        routerLink="{{ '/' + pagesRoute + link.url }}"
        class="technical-information link"
        >{{ link.name }}</a
      >
    }
  </div>
</div>

<a href="https://freematiq.com" class="dev-link" target="_blank">
  <div class="technical-information link">Разработано</div>
  <img src="/assets/icons/dev-logo.svg" alt="Freematiq" />
</a>
