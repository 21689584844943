<div class="promo">
  <div class="title-block">
    <div class="title">
      <span>Закрома</span> — сервис для продажи и покупки зерна
    </div>

    <div class="list">
      @for (item of itemsFist; track item.id) {
        <div class="item">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M15 4.5L6.75 12.75L3 9"
              stroke="#050609"
              stroke-opacity="0.8"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {{ item.name }}
        </div>
      }
    </div>
  </div>

  <div class="video-block">
    <div class="subtitle">
      Удобный интерфейс для <span>каждого</span> участника рынка
    </div>

    <div class="list-block">
      @for (item of itemsSecond; track item.id) {
        <div class="item">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="14"
            viewBox="0 0 20 14"
            fill="none"
          >
            <path
              d="M18.3334 1.5L7.33337 12.5L2.33337 7.5"
              stroke="#050609"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {{ item.name }}
        </div>
      }
    </div>

    <div class="visual-data">
      <div class="images">
        <app-promo-image
          size="small"
          image="/assets/advertising-banner-big.png"
        />
        <app-promo-image
          size="middle"
          image="/assets/advertising-banner-big.png"
        />
        <app-promo-image
          size="large"
          image="/assets/advertising-banner-big.png"
        />
        <app-promo-image
          size="small"
          image="/assets/advertising-banner-big.png"
        />
        <app-promo-image
          size="middle"
          image="/assets/advertising-banner-big.png"
        />
      </div>

      <video controls poster="/assets/advertising-banner-big.png">
        <source
          src="https://kord.frontend-dev.freematiq.com/field.mp4"
          type="video/mp4"
        />
      </video>

      <div class="images">
        <app-promo-image
          size="middle"
          image="/assets/advertising-banner-small.png"
        />
        <app-promo-image
          size="small"
          image="/assets/advertising-banner-small.png"
        />
        <app-promo-image
          size="large"
          image="/assets/advertising-banner-small.png"
        />
        <app-promo-image
          size="middle"
          image="/assets/advertising-banner-small.png"
        />
        <app-promo-image
          size="small"
          image="/assets/advertising-banner-small.png"
        />
      </div>
    </div>
  </div>

  <!-- <div class="link-block">
    <div class="subtitle">
      Хотите попробовать полный функционал сервиса абсолютно бесплатно?
    </div>
    <app-button-link [link]="trialLink" variety="secondary">
      Попробовать бесплатно
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M7.5 7L17.5 7V17"
          stroke="#050609"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.5 17L17.5 7"
          stroke="#050609"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </app-button-link>
  </div> -->
</div>
