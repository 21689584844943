import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { PasswordRecoveryService } from '../../core/passwordRecovery.service';
import { TCreatePasswordRequest } from '@models/commonTypes';
import { PasswordConfirmFormComponent } from '@components/common/password-confirm-form/password-confirm-form.component';

@Component({
  selector: 'app-recovery-set-password',
  imports: [PasswordConfirmFormComponent, FormsModule],
  templateUrl: './recovery-set-password.component.html',
  styleUrl: './recovery-set-password.component.scss',
})
export class RecoverySetPasswordComponent {
  error = this.passwordRecoveryService.error;
  loading = this.passwordRecoveryService.loading;

  constructor(private passwordRecoveryService: PasswordRecoveryService) {}

  recoverySetPassword(data: TCreatePasswordRequest) {
    this.passwordRecoveryService.recoverySetPassword(data);
  }
}
