<app-mobile-header>
  <app-search-with-button
    placeholder="Поиск по объявлениям"
    (search)="searchAnnouncements($event)"
  ></app-search-with-button>
  <app-select-location />
</app-mobile-header>

<app-title class="mobile-profile-title">Профиль</app-title>
<app-profile-sidebar />
