import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { LinkBackComponent } from '@components/ui/link-back/link-back.component';
import { MobileHeaderComponent } from '@components/common/mobile-header/mobile-header.component';
import { TabsComponent } from '@components/common/tabs/tabs.component';
import { TitleComponent } from '@components/common/title/title.component';
import { settingsProfileMenuList } from './settingsMenuList';
import { ROUTE_PROFILE } from '@settings/routes';

import { UIService } from '@services/ui.service';

@Component({
  selector: 'app-settings-profile',
  imports: [
    TitleComponent,
    TabsComponent,
    RouterOutlet,
    MobileHeaderComponent,
    LinkBackComponent,
  ],
  templateUrl: './settings-profile.component.html',
  styleUrl: './settings-profile.component.scss',
})
export class SettingsProfileComponent {
  uiService = inject(UIService);

  isMobile = this.uiService.isMobile;
  tabList = settingsProfileMenuList;

  routeProfile = `/${ROUTE_PROFILE}`;
}
