import { Component, computed, input } from '@angular/core';
import chunk from 'lodash-es/chunk';

import { PhoneNumberPipe } from '@pipes/phone-number.pipe';

@Component({
  selector: 'app-contact-block',
  imports: [PhoneNumberPipe],
  templateUrl: './contact-block.component.html',
  styleUrl: './contact-block.component.scss',
})
export class ContactBlockComponent {
  variety = input<'email' | 'phone'>('email');
  contacts = input.required<string[]>();

  phones = computed(() => {
    if (this.contacts().length) {
      return chunk(this.contacts(), 2);
    } else {
      return [];
    }
  });
}
