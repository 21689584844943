import { NgIf, ViewportScroller } from '@angular/common';
import { Component, effect, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { InfoBlockComponent } from '@components/common/info-block/info-block.component';
import { ButtonComponent } from '@components/ui/button/button.component';
import { InputAddressComponent } from '@components/ui/input-address/input-address.component';
import { InputLabelComponent } from '@components/ui/input-label/input-label.component';
import { TChangeProfileRequest } from '@models/profileTypes';
import { TAddresses } from '@models/mapTypes';
import {
  INN_PATTERN,
  ONLY_LETTERS_PATTERN,
  USER_NAME_PATTERN,
} from '@settings/validationSettings';
import { TFormattedApiError } from '@utils/formattingApiError';
import { AddressStringPipe } from '@pipes/address-string.pipe';

import { NotificationService } from '@services/notification.service';
import { ProfileService } from '@services/profile.service';
import { MapService } from '@services/map.service';

@Component({
  selector: 'app-personal-data',
  imports: [
    InputAddressComponent,
    InfoBlockComponent,
    ButtonComponent,
    InputLabelComponent,
    FormsModule,
    NgIf,
  ],
  templateUrl: './personal-data.component.html',
  styleUrl: './personal-data.component.scss',
})
export class PersonalDataComponent {
  profileService = inject(ProfileService);
  notificationService = inject(NotificationService);
  mapService = inject(MapService);
  viewport = inject(ViewportScroller);

  userNamePattern = USER_NAME_PATTERN;
  onlyLettersPattern = ONLY_LETTERS_PATTERN;
  innPattern = INN_PATTERN;

  // Заполнение адреса
  initialAddress = signal<string>('');
  addressList = signal<TAddresses>([]);
  addressesRequested = signal<boolean>(false);
  loadingAddressList = signal(false);
  showErrorAddress = signal(false);
  errorAddress = signal('');

  profileData = this.profileService.profileData;
  loading = signal(false);
  apiError = signal<string | null>(null);

  profileChangeForm: TChangeProfileRequest = {
    first_name: '',
    last_name: '',
    middle_name: '',
    inn: '',
    address_data: null,
  };

  // Установка полей формы при обновлении профиля
  setFormValues = effect(() => {
    this.profileChangeForm = {
      first_name: this.profileData()?.first_name || '',
      last_name: this.profileData()?.last_name || '',
      middle_name: this.profileData()?.middle_name || '',
      inn: this.profileData()?.inn || '',
      address_data: null,
    };

    const address = this.profileData()?.address;

    if (address) {
      this.initialAddress.set(address);
    }
  });

  // Запрос списка адресов и выбор адреса
  onSearchAddress(address: string) {
    if (address && address.trim().length >= 3) {
      this.loadingAddressList.set(true);

      this.mapService.findAddress(address).subscribe({
        next: (response) => {
          this.addressList.set(response);
          this.addressesRequested.set(true);

          this.loadingAddressList.set(false);

          const currentAddress = this.addressList().find(
            (item) =>
              new AddressStringPipe().transform(item).toLowerCase() ===
              address.toLowerCase(),
          );

          if (
            currentAddress &&
            (currentAddress.street || currentAddress.house_block_flat) &&
            currentAddress.lat &&
            currentAddress.lon
          ) {
            this.profileChangeForm.address_data = {
              region: currentAddress.region,
              area: currentAddress.area || null,
              city: currentAddress.city || null,
              settlement: currentAddress.settlement || null,
              street: currentAddress.street || null,
              house_block_flat: currentAddress.house_block_flat || null,
            };

            this.errorAddress.set('');
          } else {
            this.profileChangeForm.address_data = null;
            this.errorAddress.set(
              'Укажите адрес с точностью до улицы или оставьте поле пустым',
            );
          }
        },
        error: (error: TFormattedApiError) => {
          this.loadingAddressList.set(false);
          this.addressList.set([]);

          if (error.formattedErrorMessage)
            this.notificationService.error(error.formattedErrorMessage);
        },
      });
    } else {
      this.profileChangeForm.address_data = null;
      this.addressList.set([]);
      this.addressesRequested.set(false);
      this.errorAddress.set('');
    }
  }

  handleBlurAddress() {
    this.showErrorAddress.set(true);
  }

  handleSubmit() {
    this.loading.set(true);

    const requestData = this.profileChangeForm.address_data
      ? this.profileChangeForm
      : {
          ...this.profileChangeForm,
          address_data: null,
        };

    this.profileService.editProfile(requestData).subscribe({
      next: (response) => {
        this.profileService.setProfileData(response);
        this.loading.set(false);
        this.apiError.set(null);

        this.notificationService.success('Личные данные успешно обновлены');
      },
      error: (error: TFormattedApiError) => {
        this.loading.set(false);

        this.apiError.set(error.formattedErrorMessage);
        this.viewport.scrollToPosition([0, 0]);
      },
    });
  }
}
